import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import clsx from 'clsx';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { Header, Footer, Layout, Section, Article, Container } from 'gatsby-theme-core/src/components';
import { Typography, Grid, Button } from '@material-ui/core';
import imagePathGenerator from '../utils/imagePathGenerator';
import classNames from 'classnames';

const LOGO = require('../images/fowf-icon.png');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& a': {
        color: '#57a88d',
      },
    },
    postImage: {
      width: '100%',
      alignSelf: 'center',
      boxShadow: theme.shadows[12],
    },
    article: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '800px',
      color: '#525960',
    },
    aws: {
      color: '#f19f51',
    },
  }),
);

export type PostProps = { spacing: number; data: any } & Pick<DefaultComponentProps, 'path' | 'classname' | 'children'>;

export const Post = ({ ...props }: PostProps) => {
  const classes = useStyles(props);

  const { mdx } = props.data;
  const { title, images, slug, date, backlink } = mdx.frontmatter;

  // Prevent rerenders when footnotes/popovers change.
  const content = useMemo(() => <MDXRenderer>{mdx.body}</MDXRenderer>, undefined);
  return (
    <Layout>
      <Header classname="dark-green" logo={LOGO} />
      <Container classname={classNames(classes.root)}>
        <Section spacing={6} classname={classNames('pb-0')}>
          <Article spacing={3} classname={classes.article}>
            <Typography variant="h2" component="span" className="black-text">
              {title}
            </Typography>
            <Grid container justify="center">
              <Section spacing={3}>
                <img className={classes.postImage} src={imagePathGenerator(images, slug)} alt="post image"></img>
              </Section>
            </Grid>
            <Typography className="py-2 pb-0 semi-bold grey-text" variant="body1" component="span">
              Originally Published by{' '}
              <a className={clsx('semi-bold', classes.aws)} href={backlink}>
                Allwork.Space
              </a>{' '}
              on {date}
            </Typography>
            <Typography className="black-text" component="span">
              {content}
            </Typography>
          </Article>
        </Section>
        <Section spacing={4} classname="center">
          <Link to="/blog" className="primary-dark-text no-decoration">
            <Button variant="contained">Go Back</Button>
          </Link>
        </Section>
      </Container>
      <Footer logo={LOGO} />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        publish
        images
        excerpt
        date(formatString: "MMMM DD, YYYY")
        backlink
      }
    }
  }
`;
